@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

textarea:focus,
input:focus {
  outline: none;
}
